import { getLinkDetect3G } from '~/common/api/viettel'
export default function ({ store, redirect }) {
  // If the user is not authenticated
  if (!store.state.authenticated && store.state.isMobile) {
    getLinkDetect3G()
      .then((response) => {
        console.log(response)
        const data = response.data
        if (data) {
          const link = data.link
          if (link) {
            return redirect(link)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
